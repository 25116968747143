import { FormattedMessage, injectIntl, Link } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as SvgOverview } from './assets/overView.svg';
import { ReactComponent as ChevronLeft } from './assets/chevronLeft.svg';
import { ReactComponent as ChevronRight } from './assets/chevronRight.svg';

const PrevNextNavi = ({
  prevProjectUrl,
  nextProjectUrl,

}) => (
  <div id="projectnavi">
    <ul>
      <li>
        <Link to={`/${prevProjectUrl}`}>
          <div className="icon icon_left"><ChevronLeft /></div>
          {' '}
          <FormattedMessage id="prevNextNavi.prevProject" />
        </Link>
      </li>
      <li>
        <Link to="/projects">
          <div className="icon icon_overview"><SvgOverview /></div>
          {' '}
          <FormattedMessage id="prevNextNavi.overView" />
        </Link>
      </li>
      <li>
        <Link to={`/${nextProjectUrl}`}>
          <FormattedMessage id="prevNextNavi.nextProject" />
          <div className="icon icon_right"><ChevronRight /></div>
        </Link>
      </li>
    </ul>
  </div>
);

PrevNextNavi.propTypes = {
  prevProjectUrl: PropTypes.string.isRequired,
  nextProjectUrl: PropTypes.string.isRequired,

};

export default injectIntl(PrevNextNavi);
