import { graphql } from 'gatsby';
import React from 'react';
// import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../../components/Layout';
import { ProjectIntro, ProjectOutro, ProjectTeaser } from '../../components/Project';
import '../../styles/index.less';
import '../../styles/projects.less';


const Index = ({ pageContext, data, ...props }) => {
  const { projects: { edges: projects } } = data;
  const test = 'test';
  const { language: lang } = pageContext;


  return (
    <Layout {...props}>
      <div className="index">
        <ProjectIntro lang="de" />
        {/* <pre>{JSON.stringify(projects, null, 4)}</pre> */}
        {/* <pre>{JSON.stringify(pageContext.teaserImages, null, 4)}</pre> */}
        {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
        <div className="projects">
          <div className="projectslist">
            <div className="isotope">

              {projects.map(({
                node: {
                  id,
                  frontmatter: {
                    teaserSize, titleTile, seoUrl, date, teaserImg,
                  },
                },
              }, n) => (
                // <h1 key={id}>{titleTile.en} /</h1>
                // <pre>{JSON.stringify(titleTile.de, null, 2)}</pre>
                <ProjectTeaser
                  teaserSize={teaserSize}
                  seoUrl={seoUrl}
                  title={titleTile[lang]}
                  date={date}
                  imageS={data.teaserImages.edges.find(({ node }) => node.sizes.originalName === teaserImg.s)}
                  imageM={data.teaserImages.edges.find(({ node }) => node.sizes.originalName === teaserImg.m)}
                  imageL={data.teaserImages.edges.find(({ node }) => node.sizes.originalName === teaserImg.l)}
                  // image={data.teaserImages.edges.find(({ node }) => node.sizes.originalName === teaserImg[teaserSize])}
                  key={id}
                />

              ))}
            </div>
          </div>
        </div>
        <ProjectOutro lang="de" />
      </div>
    </Layout>
  );
};
export default Index;

export const projectQuery = graphql`
  query projects($teaserImages: [String]) {
    projects: allMdx(filter: {frontmatter: {featured: {eq: true}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            seoUrl
            
            titleTile {
              de
              en
            }
            date 
            teaserSize
            teaserImg {
              l
              m
              s
            }
          }              
        }
      }
    }

    teaserImages: allImageSharp(filter: {sizes: {originalName: {in: $teaserImages}}}) {
      edges {
        node {
          id
          sizes(maxWidth: 950, srcSetBreakpoints: [1110, 950, 460]) {
            originalName
            ...GatsbyImageSharpSizes
          }
        }
      }
    }

  }
`;
