import React from 'react';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';

const ProjectIntro = (props) => {
  const intl = useIntl();
  return (
    <div className="project intro">
      <FormattedHTMLMessage id="home.text" />
      {/* <h1 className="colorHighlight">
        {intl.formatMessage({ id: 'home.headline' })}
      </h1>
      <p>
        {intl.formatMessage({ id: 'home.copy' })}
      </p> */}
    </div>
  );
};

export default ProjectIntro;
