import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby-plugin-intl';
import classNames from 'classnames';
import { ReactComponent as Chevron } from './assets/chevron.svg';

const getSize = (elem) => {
  let size;
  if (elem.originalName.indexOf('teaser-s.jpg') !== -1) {
    size = 's';
  } else if (elem.originalName.indexOf('teaser-m.jpg') !== -1) {
    size = 'm';
  } else if (elem.originalName.indexOf('teaser-l.jpg') !== -1) {
    size = 'l';
  }
  return size;
};


const ProjectTeaser = ({
  seoUrl, teasers, title, teaserSize,
  imageS: { node: imageS }, imageM: { node: imageM }, imageL: { node: imageL },
}) => {
  const wrapperClassNames = classNames(
    'projects_item',
    ['item_1'],
    [`item_size_${teaserSize}`],
  );

  const teaserClassNames = classNames(
    'teaser_img',
    `size_${teaserSize}}`,
    // `size_${getSize(elem)}`,
  );
  return (
    <div className={wrapperClassNames}>
      <Link to={`/${seoUrl}`}>
        <div className="inner">
          <div className="teaser">

            {/* {
              teasers.map((elem) => (
                ))
              } */}
            <div
              className="teaser_img size_s"
              // key={`img_${imageS.originalName}`}
            >
              <Img sizes={imageS.sizes} />
            </div>

            <div
              className="teaser_img size_m"
              // key={`img_${imageM.originalName}`}
            >
              <Img sizes={imageM.sizes} />
            </div>

            <div
              className="teaser_img size_l"
              // key={`img_${imageL.originalName}`}
            >
              <Img sizes={imageL.sizes} />
            </div>


          </div>
          <div className="hilight">
            <div className="deeplink">
              <h2 className="headline">{title}</h2>
              <div className="chevron">
                <Chevron />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

// class ProjectTeaser extends React.Component {
//   render() {
//     const { seoUrl } = this.props;
//     // const deeplink = `/${language}/projects/${slug}/`;

//     return (
//       <div className={`projects_item item_${this.props.count} item_size_${this.props.teaserSize}`}>
//         <Link to={deeplink}>
//           <div className="inner">
//             <div className="teaser">

//               {

//                               this.props.teasers.map((elem) => (
//                                 <div className={`teaser_img size_${getSize(elem)}`} key={`img_${elem.originalName}`}>
//                                   <Img sizes={elem} />
//                                 </div>
//                               ))

//                           }

//             </div>
//             <div className="hilight">


//               <div className="deeplink">
//                 <h2 className="headline">{this.props.title}</h2>
//                 <div className="chevron">
//                   {chevron}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Link>
//       </div>
//     );
//   }
// }

export default ProjectTeaser;
