import React from 'react';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import Obfuscate from 'react-obfuscate';
// import mailto from 'react-mailto'

const ProjectOutro = (props) => {
  const intl = useIntl();
  return (
    <div className="project outro">
      <div className="copy">
        <FormattedHTMLMessage id="home.teaser.copy" />
        {/* {intl.formatMessage({ id: 'home.teaser.copy' })} */}
      </div>
      <div className="calltoaction">

        <Obfuscate style={{ display: 'inline-block' }} email="recognize@lineareal.de">
          <button type="button" className="contact_action">
            <FormattedHTMLMessage id="home.teaser.button" />
            {/* {intl.formatMessage({ id: 'home.teaser.button' })} */}
          </button>
        </Obfuscate>

      </div>
    </div>
  );
};

export default ProjectOutro;
