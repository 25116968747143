import React from 'react';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';

const ProjectIntro = (props) => {
  const intl = useIntl();
  return (
    <div className="project intro">
      <h1 className="colorHighlight">
        <FormattedHTMLMessage id="projectsOverview.headline" />
      </h1>
      {/* <h1 className="colorHighlight">
        {intl.formatHTMLMessage({ id: 'projectsOverview.headline' })}
      </h1> */}
    </div>
  );
};

export default ProjectIntro;
